import { graphql, Link } from "gatsby";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navigation/Navbar";
import SeoHelmet from "../components/Seo/SeoHelmet";
import { componentBuilder } from "../helpers/componentsHelper";
import MakeSlug, { DeSlug } from "../helpers/makeSlug";
import MaxContent from "../layout/MaxContent";
import { IFAQ } from "../components/FAQ/Faq";
import { GatsbyImage } from "gatsby-plugin-image";
import TertiaryHeader from "../components/Headers/TertiaryHeader";
import { translate } from "../contexts/i18n.context";
import { GlobalIcon } from "get-life-storybook-ts";

export default ({ data }: any) => {
  const pageData = data.page;
  const navbarComponent = pageData.navbar;
  const pageComponents = pageData.Content;
  const seoComponent = pageData.SEO;
  const footerComponent = pageData.footer;
  const content = componentBuilder(pageComponents);

  let sliced = pageData?.updatedAt?.slice(0, 10) ?? "";
  let [year, month, day] = sliced.split("-");
  let formattedDateString = `${day}/${month}/${year.slice(-2)}`;

  const [activeCategory, setActiveCategory] = useState<string>("");
  const Categories = data.allStrapiFaq.nodes.reduce(
    (acc: Map<string, Array<any>>, next: IFAQ) => {
      const category = acc.get(next.Category);
      if (!category) {
        acc.set(next.Category, [{ title: next.Title, url: next.CustomUrl }]);
      } else {
        category.push({ title: next.Title, url: next.CustomUrl });
      }
      return acc;
    },
    new Map()
  );

  useEffect(() => {
    for (let [key, value] of Categories) {
      const hasValue = value.indexOf(pageData.Title);
      if (hasValue > -1) {
        setActiveCategory(key);
      }
    }
  }, []);

  return (
    <>
      {/* @ts-ignore */}
      <SeoHelmet data={seoComponent} locale={pageData.locale} />

      {navbarComponent && <Navbar data={navbarComponent} />}

      <TertiaryHeader data={pageData.Header} />

      <MaxContent size="content">
        <div className="grid lg:grid-cols-12 gap-10 my-[80px]">
          {/* sidebar */}
          <div className="hidden md:block lg:col-span-4 order-last lg:order-first bg-inactive-gray px-[20px] h-min">
            <div className="my-[30px]">
              <Link to={translate("faq.url")} className="no-underline">
                <div className="flex flex-row items-center gap-[15px]">
                  <GlobalIcon iconName="ArrowBackIcon" size="XS" color="var(--primary-color)" />
                  <>FAQ</>
                </div>
              </Link>
            </div>
            {Array.from(Categories).map(([category, _]: any) => {
              return (
                <CategoryTab
                  allCategorys={Categories}
                  category={category}
                  activeCategory={activeCategory}
                  setActiveCategory={setActiveCategory}
                />
              );
            })}
          </div>
          {/* faq content */}
          <div className="flex flex-col lg:col-span-8 p-4 md:p-0">
            <Link to={translate("faq.url")} className=" md:hidden mb-[20px] no-underline">
              <div className="flex flex-row items-center gap-[15px]">
                <GlobalIcon iconName="ArrowBackIcon" size="XS" color="var(--primary-color)" />
                <>FAQ</>
              </div>
            </Link>

            {pageData.Author && (
              <div className="flex flex-row border-t-[1px] border-t-legal border-b-[5px] border-b-[var(--primary-color)] py-[25px] gap-[20px] mb-[50px]">
                {pageData.AuthorImage && (
                  <GatsbyImage
                    image={
                      pageData.AuthorImage.file.childImageSharp.gatsbyImageData
                    }
                    alt={pageData.AuthorImage.alternativeText}
                    className="rounded-full w-[56px] h-[56px]"
                  />
                )}

                <div className="flex flex-col">
                  <p className="h6-default">
                    {translate("faq.writeBy")}<span>{pageData.Author}</span>
                  </p>
                  <p className="text-legal-medium text-primaryCharcoal">
                    {translate("faq.updatedOn")} {formattedDateString}
                  </p>
                </div>
              </div>
            )}
            <div className="flex flex-col gap-[30px] faq-content">
              {content?.map((block, id) => (
                <div id="FAQ-content" key={id}>
                  {block}
                </div>
              ))}
            </div>
          </div>
        </div>
      </MaxContent>
      {footerComponent && <Footer data={footerComponent} />}
    </>
  );
};

const CategoryTab = ({
  allCategorys,
  category,
  activeCategory,
  setActiveCategory,
}: any) => {
  const questions = allCategorys.get(category);

  return (
    <div key={category}>
      <div
        className={`flex flex-row items-center justify-between cursor-pointer  py-7 text-black
          border-t${activeCategory === category ? "-2" : ""}
          border-${activeCategory === category ? "purple" : "legal-gray"}
          ${activeCategory === category ? "text-purple" : ""}`}
        onClick={() => setActiveCategory(category)}
      >
        <p className="font-bold text-black">{category}</p>
        <GlobalIcon
          size="XXS"
          iconName={activeCategory === category ? "MinusIcon" : "PlusIcon"}
          color={activeCategory === category ? "var(--primary-color)" : "#000000"}
        />
      </div>
      <div
        className={`flex flex-col gap-[25px] ${
          activeCategory === category ? "mb-[20px]" : ""
        }`}
      >
        {activeCategory === category &&
          questions.map((question: any) => {
            let url = `${translate("faq.url")}${
              question.url ?? MakeSlug(question.title)
            }/`.replace(/\/\//g, "/");

            return (
              <Link
                to={url}
                className="text-body-sm no-underline text-black hover:text-purple hover:underline"
              >
                {question.title}
              </Link>
            );
          })}
      </div>
    </div>
  );
};
export const faqQuery = graphql`
  query ($id: String!) {
    allStrapiFaq {
      nodes {
        Title
        Category
        CustomUrl
      }
    }
    page: strapiFaq(id: { eq: $id }) {
      id
      Title
      updatedAt
      publishedAt
      Author
      locale
      AuthorImage {
        file {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      Header {
        Title
      }
      SEO {
        SeoTitle
        SeoDescription
        DoNotIndex
        DoNotFollow
        Schema
        CanonicalTag
        TwitterImageUrl
        FacebookImageUrl
        ImageUrl
        Image {
          file {
            childImageSharp {
              gatsbyImageData
            }
            url
          }
        }
        Links {
          HrefLang
          Href
        }
      }
      navbar {
        DropdownLabel
        DropdownLinks {
          Label
          Url
        }
        Links {
          Label
          Url
        }
        Phone
        HideAccount
        HidePhone
        AccountLabel
        AccountIcon {
          file {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        AccountUrl
        ButtonLabel
        ButtonUrl
        idButton
      }
      footer {
        group_title_1
        group_title_2
        group_title_3
        group_title_4
        group_links_1 {
          Label
          Url
        }
        group_links_2 {
          Label
          Url
        }
        group_links_3 {
          Label
          Url
        }
        group_links_4 {
          Label
          Url
        }
        group_links_bottom {
          Label
          Url
        }
        contact_address
        contact_phone_number
        contact_phone_subtitle
        contact_title
        contact_map_link {
          Label
          Url
          id
        }
        form_header
        form_submit_button_link {
          Label
          Url
        }
        form_text_placeholder
        facebook_url
        instagram_url
        linkedin_url
        twitter_url
      }
      # CONTENT COMPONENT START
      Content {
        __typename
        ... on StrapiComponentBasicButton {
          id
          Secondary
          Label
          Url
          idButton
          NewTab
          NoFollow
        }
        ... on StrapiComponentBasicTitleText {
          id
          Title
          Text
          TitleType
        }
        ... on StrapiComponentBasicTitle {
          id
          Title
        }
      }
    }
  }
`;
